import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-scroll';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';
import useBeacon from 'utils/useBeacon';
import track from 'utils/track';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section from 'components/Elements/Section';
import HeaderVideo from 'components/Elements/HeaderVideo';
import SalesCopy from 'components/SalesCopy';
import ModalExitSales from 'components/Modals/ModalExitSales';
import Footer from 'components/Elements/Footer';

export default function SalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  ...props
}) {
  const pageTitle =
    'Change the way the world eats with Food Revolution Network’s Plant-Based Coaching Certification Program';
  const pageDescription = 'Get everything you need to transform your clients’ lives and your life.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x1200-20221004.png';
  const shareUrl = 'https://certification.foodrevolution.org/join/';
  const facebookDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x675-20221004.png';
  const pinterestDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1000x1500-20221004.png';

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = defaultSchedule.phase;
  const phaseIteration = defaultSchedule.phaseIteration;
  const beforeRegistrationOpen = phase === 'beforeRegistration';
  const registrationClosed = phase === 'after';
  const noVideo = registrationClosed || beforeRegistrationOpen;
  const ctaVisible = defaultSchedule.ctaVisible;
  const timerDate = new Date(defaultSchedule.phaseTimerEnd).getTime();

  const productId = 'pbcc';
  const productSku = 'pbcc';
  const productName = 'Plant-Based Coaching Certification';
  const productSlug = phaseIteration === 'insiderPromo' ? 'pbcc-2025-deposit-insider' : 'pbcc-2025-deposit';
  const productValue = 100;
  const waitListFormUrl = 'https://forms.gle/Ph8UT3auQA4j3EGFA';

  useEffect(() => {
    track('Product Viewed', {
      product_id: productId,
      sku: productSku,
      name: productName,
      variant: productSlug,
      value: productValue,
      currency: 'usd'
    });
  });

  useBalanceText();
  useBeacon();
  return (
    <div className={classNames(`page`, `d-flex`, `flex-column`, `page-nosocial`)}>
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          bgColor="purple"
          lockupWhite
          adLockup={variant === 'ad'}
          logoUrlOverride={variant === 'ad' ? undefined : 'https://cdn.foodrevolution.org/pbcc/pbcc-logo-white.svg'}
          logoAltOverride={variant === 'ad' ? undefined : 'plant-based coaching logo'}
          logoWidthOverride={variant === 'ad' ? undefined : 300}
          logoHeightOverride={variant === 'ad' ? undefined : 59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        <Section id="pbcc-header-cta" color="black" className="section-angle-first text-center text-white">
          <Container>
            <Row>
              <Col>
                <h1 className="section-heading mb-3">Plant-Based Coaching Certification</h1>
                <h3 className={classNames(`mt-0`, noVideo && `mb-0`)}>
                  <i>Bring the food revolution to the world</i>
                </h3>

                {registrationClosed && (
                  <>
                    <p className="balance-text mt-5">
                      <b>The next cohort will start in January. Be the first to know when we open enrollment!</b>
                    </p>
                    <p>
                      <a className="link-white text-underline" href={waitListFormUrl} target="_blank">
                        Click here to be notified the moment the doors open.
                      </a>
                    </p>
                  </>
                )}
              </Col>
            </Row>
            {!noVideo && (
              <Row className="justify-content-center">
                <Col lg="10" className="video-col px-0 px-sm-3">
                  <HeaderVideo
                    url="https://www.youtube.com/watch?v=C6ICmSeJSSU"
                    thumbnail="https://cdn.foodrevolution.org/pbcc/video-thumb/pbcc-sales-dynamic-thumbnail-20230927.gif"
                    noButton
                    noFixed={noFixedVideo}
                    sku={productSku}
                    slug={productSlug}
                    price={productValue}
                    label="PBCC - Sales"
                    bgColor="black"
                    ctaContent={
                      <>
                        <h5 className="mt-0 d-none d-md-block">
                          Are you ready to be a LEADER in <br className="d-none d-lg-block" />
                          the food revolution movement?
                        </h5>
                        {ctaVisible && (
                          <Button
                            color="cta"
                            size="lg"
                            spy={true}
                            offset={-110}
                            to="pbcc-cta-2"
                            smooth={true}
                            href="#"
                            tag={Link}
                          >
                            I’m Ready
                          </Button>
                        )}
                      </>
                    }
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Section>

        <SalesCopy version="sales" variant={variant} ctaOverride={ctaOverride} timerDate={timerDate} />
      </div>

      {!noExitIntent && !registrationClosed ? <ModalExitSales /> : null}

      <div className="footer-content mt-auto">
        <Footer adVariant={variant === 'ad'} />
      </div>
    </div>
  );
}
