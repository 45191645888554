import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
import {Link} from 'react-scroll';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';
import track from 'utils/track';

import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import {SalesDisclaimer} from 'components/SalesCta';
import {ImgCard, VerticalCard} from 'components/Elements/ImgBullet';
import {ListIcon} from 'components/Elements/IconList';
import {PillarModal, PillarModulesCondensed} from 'components/ModuleAccordion';
import FaqList from 'components/FaqList';
import {ListReferenceItem, ListReferences} from 'components/Elements/ListReference';
import {TestimonialBubble} from 'components/Testimonial';

//@ts-ignore
import {iconList, references} from 'data/pbcc-content.json';
//@ts-ignore
import {bonus} from 'data/bonus.json';
//@ts-ignore
import {speakers} from 'data/module.json';
//@ts-ignore
import {studentSpotlight} from 'data/testimonials.json';

export default function SalesCopy({variant = 'default', version = 'sales', ctaOverride = null, timerDate, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const masterclassOverride = version === 'masterclass';
  const afterMasterclass = webinarSchedule.phase === 'after';

  const phase = defaultSchedule.phase;
  const phaseIteration = defaultSchedule.phaseIteration;
  const beforeRegistrationOpen = phase === 'beforeRegistration';
  const registrationClosed = phase === 'after';
  const clarityCall = defaultSchedule.clarityCall;
  const ctaVisible = defaultSchedule.ctaVisible || masterclassOverride;

  const ctaBonus =
    !beforeRegistrationOpen ||
    phaseIteration === 'insiderPromo' ||
    phaseIteration === 'workshop' ||
    phaseIteration === 'retail' ||
    phaseIteration === 'webinar' ||
    !registrationClosed;
  const wlcBonus = defaultSchedule.wlcBonus || masterclassOverride;
  const mastermindBonus = defaultSchedule.mastermindBonus || masterclassOverride;
  const frsBonus = defaultSchedule.frsBonus || masterclassOverride;

  const frsSpecialBonus =
    (phaseIteration === 'insiderPromo' && !masterclassOverride) ||
    (phaseIteration === 'workshop' && !masterclassOverride);

  const bonusesValue = masterclassOverride
    ? '$14,212'
    : registrationClosed
    ? '$5,848'
    : {
        insiderPromo: '$15,000',
        workshop: '$15,000',
        retail: '$14,212',
        webinar: '$14,212',
        lateApp: '$5,848',
        finalChance: '$5,848'
      }[phaseIteration];

  const productId = 'pbcc';
  const productSku = 'pbcc';
  const productName = 'Plant-Based Coaching Certification';
  const productSlug = masterclassOverride
    ? 'pbcc-2025-deposit-m'
    : phaseIteration === 'insiderPromo'
    ? 'pbcc-2025-deposit-insider'
    : 'pbcc-2025-deposit';
  const productValue = 100;
  const waitListFormUrl = 'https://forms.gle/Ph8UT3auQA4j3EGFA';

  useEffect(() => {
    track('Product Viewed', {
      product_id: productId,
      sku: productSku,
      name: productName,
      variant: productSlug,
      value: productValue,
      currency: 'usd'
    });
  });

  const programStart = 'January 27, 2025';
  const programStartNoYear = 'January 27';
  const programYear = '2025';
  const welcomeCallDate = 'January 14, 2025';

  const appDeadline =
    phaseIteration === 'insiderPromo' && !masterclassOverride
      ? 'August 26, 2024'
      : phaseIteration === 'lateApp'
      ? 'January 22, 2025'
      : phaseIteration === 'finalChance'
      ? 'January 31, 2025'
      : 'January 8, 2025';

  const tuitionDue =
    phaseIteration === 'insiderPromo' && !masterclassOverride
      ? 'August 30, 2024'
      : phaseIteration === 'finalChance'
      ? 'January 31, 2025'
      : phaseIteration === 'lateApp' || registrationClosed
      ? 'January 27, 2025'
      : 'January 13, 2025';

  const insiderPromoMonthlyPayment = '$875';
  const insiderPromoMonthlyTotal = '$5,250';
  const insiderPromoFullTotal = '$4,797';
  const tuitionMonthlyPayment = '$1,097';
  const tuitionMonthlyPaymentDiscount = '$997';
  const tuitionMonthlyTotal = '$6,582';
  const tuitionMonthlyTotalDiscount = '$5,982';
  const tuitionFullPayment = '$5,997';
  const tuitionFullPaymentDiscount = '$4,997';

  const tuitionDiscount =
    masterclassOverride && afterMasterclass
      ? false
      : {
          insiderPromo: true,
          workshop: true,
          retail: false,
          webinar: true,
          lateApp: false,
          finalChance: false
        }[phaseIteration];

  const tuitionMonthly =
    masterclassOverride && !afterMasterclass
      ? tuitionMonthlyPaymentDiscount
      : masterclassOverride && afterMasterclass
      ? tuitionMonthlyPayment
      : {
          insiderPromo: insiderPromoMonthlyPayment,
          workshop: tuitionMonthlyPaymentDiscount,
          webinar: tuitionMonthlyPaymentDiscount,
          retail: tuitionMonthlyPayment,
          lateApp: tuitionMonthlyPayment,
          finalChance: tuitionMonthlyPayment
        }[phaseIteration];

  const tuitionMonthlyFull =
    masterclassOverride && !afterMasterclass
      ? tuitionMonthlyTotalDiscount
      : masterclassOverride && afterMasterclass
      ? tuitionMonthlyTotal
      : {
          insiderPromo: insiderPromoMonthlyTotal,
          workshop: tuitionMonthlyTotalDiscount,
          webinar: tuitionMonthlyTotalDiscount,
          retail: tuitionMonthlyTotal,
          lateApp: tuitionMonthlyTotal,
          finalChance: tuitionMonthlyTotal
        }[phaseIteration];

  const tuitionFull =
    masterclassOverride && !afterMasterclass
      ? tuitionFullPaymentDiscount
      : masterclassOverride && afterMasterclass
      ? tuitionFullPayment
      : {
          insiderPromo: insiderPromoFullTotal,
          workshop: tuitionFullPaymentDiscount,
          webinar: tuitionFullPaymentDiscount,
          retail: tuitionFullPayment,
          lateApp: tuitionFullPayment,
          finalChance: tuitionFullPayment
        }[phaseIteration];

  const currentTime = new Date().getTime();
  const noTimer = phaseIteration === 'insiderPromo';

  function ApplyNowCta({className = undefined}) {
    return (
      <div className={classNames(`apply-now`, className)}>
        {beforeRegistrationOpen ? (
          <>
            <h4 className="section-heading text-uppercase mb-3">Important Note:</h4>
            <p className="balance-text">We’re still getting the registration process worked out.</p>
            <p className="balance-text">
              To enter your name and email so we can make sure to let you know when registration opens,{' '}
              <a className={classNames(`text-underline`, `link-purple`)} href={waitListFormUrl}>
                click here
              </a>
              .
            </p>
            <h5 className="m-0">Thanks!</h5>
          </>
        ) : registrationClosed ? (
          <>
            <p className="balance-text">
              <b>The deadline to apply for the Coaching Program has passed.</b>
            </p>
            <p className="balance-text">
              If you’ve already submitted a deposit, you have until {tuitionDue} to submit payment for inclusion in the
              program.
            </p>
            <p className="balance-text">
              <a className={classNames(`text-underline`, `link-purple`)} href={waitListFormUrl} target="_blank">
                Click here to be notified if we offer this program again in the future.
              </a>
            </p>
          </>
        ) : (
          <>
            {masterclassOverride ? (
              <>
                {!afterMasterclass && (
                  <h4 className="section-heading text-uppercase mb-3">Special Offer Available Now</h4>
                )}
                {currentTime <= timerDate && <CountdownTimer time={timerDate} className="countdown-dark" />}
              </>
            ) : (
              <>
                {phaseIteration === 'lateApp' || phaseIteration === 'finalChance' ? (
                  <p className="balance-text mb-4">
                    The deadline to apply for the Coaching Program has passed. But, you’re in luck. We’re extending the
                    timeline for latecomers!
                  </p>
                ) : null}
                {!noTimer && (
                  <>
                    <h5 className="section-heading mb-2">
                      {
                        {
                          workshop: 'Limited Time Bonuses Expiring in:',
                          retail: 'Limited Time Bonuses Expiring in:',
                          webinar: 'Tuition Discount & Limited Time Bonus Expiring in:',
                          lateApp: <>Application Deadline: {appDeadline}</>,
                          finalChance: <>Application Deadline: {appDeadline}</>
                        }[phaseIteration]
                      }
                    </h5>
                    <CountdownTimer time={timerDate} className="countdown-dark" />
                  </>
                )}
                {phaseIteration === 'insiderPromo' && (
                  <>
                    <h4 className="section-heading mb-5">The Next Cohort Begins in January, {programYear}.</h4>
                    <div className="d-flex justify-content-center mb-5">
                      <div className="box-shadow-black-50 rounded">
                        <div className="background-black text-white p-3 rounded-top">
                          <h5 className="m-0">Join the Waitlist</h5>
                        </div>
                        <div className="p-4 my-auto">
                          <p className="balance-text m-0">
                            <a
                              className={classNames(`text-underline`, `link-purple`)}
                              href={waitListFormUrl}
                              target="_blank"
                            >
                              Click here to be notified the moment the doors open.
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3 className="mt-4">Or apply now and receive the FRN Insider price* and limited-time bonuses</h3>
                  </>
                )}
              </>
            )}

            {ctaBonus && (
              <>
                <h5 className="mt-4 mb-4">
                  {masterclassOverride ? (
                    <>
                      {afterMasterclass ? (
                        <>Apply now to receive these limited-time bonuses:</>
                      ) : (
                        <>Up to $1,000 OFF AND receive these limited-time bonuses:</>
                      )}
                    </>
                  ) : (
                    <>
                      {
                        {
                          workshop: 'Apply now to receive these limited-time bonuses:',
                          retail: 'Apply now to receive these limited-time bonuses:',
                          webinar: 'Apply now to get up to $1,000 OFF AND receive these limited-time bonuses:'
                        }[phaseIteration]
                      }
                    </>
                  )}
                </h5>
                <div className="d-flex justify-content-center mb-0">
                  <ListIcon className="text-left m-0">
                    {(masterclassOverride && !afterMasterclass) ||
                    phaseIteration === 'insiderPromo' ||
                    phaseIteration === 'workshop' ||
                    phaseIteration === 'webinar' ? (
                      <>
                        Tuition Discount:{' '}
                        <del>
                          {tuitionMonthlyTotal} ({tuitionMonthlyPayment}/month for 6 months)
                        </del>
                        <br className="d-none d-xl-block" />{' '}
                        <span className="text-green">
                          {tuitionMonthlyFull} (6 monthly payments of {tuitionMonthly})
                        </span>{' '}
                        or <del>{tuitionFullPayment}</del> <span className="text-green">{tuitionFull}</span>
                      </>
                    ) : null}

                    {(phaseIteration === 'insiderPromo' && !masterclassOverride) ||
                    (phaseIteration === 'workshop' && !masterclassOverride) ? (
                      <>2020–2025 Food Revolution Summit Empowerment Packages (Value $1,182)</>
                    ) : null}

                    {(phaseIteration === 'retail' && !masterclassOverride) ||
                    (phaseIteration === 'webinar' && !masterclassOverride) ? (
                      <>2024–2025 Food Revolution Summit Empowerment Packages (Value $394)</>
                    ) : null}

                    {masterclassOverride ||
                    phaseIteration === 'insiderPromo' ||
                    phaseIteration === 'workshop' ||
                    phaseIteration === 'retail' ||
                    phaseIteration === 'webinar' ? (
                      <>{wlcBonus && <>Unlimited Membership in WHOLE Life Club (Value $497/year)</>}</>
                    ) : null}

                    {masterclassOverride || phaseIteration === 'retail' || phaseIteration === 'webinar' ? (
                      <>{mastermindBonus && <>Ocean Robbins’ Food Revolution Mastermind (Value $3,000)</>}</>
                    ) : null}
                  </ListIcon>
                </div>
                {phaseIteration === 'lateApp' ? (
                  <>
                    <h5 className="mt-0 mb-5">
                      Pay by {tuitionDue}, to get in late (with recordings of the Welcome Calls and still getting the
                      whole program!)
                    </h5>
                  </>
                ) : phaseIteration === 'finalChance' ? (
                  <>
                    <h5 className="mt-0 mb-5">
                      Pay by {tuitionDue}, to get in late (with recordings of the Welcome Calls and jumpstarting 1 week
                      into the program!)
                    </h5>
                  </>
                ) : (
                  <p className="mb-5">
                    <b>
                      <i>And more — see the complete list of more than $10,000 worth of bonuses below!</i>
                    </b>
                  </p>
                )}
              </>
            )}

            <Row className={classNames(`align-items-stretch`)}>
              <Col lg="6" className="d-flex flex-column mb-4 mb-lg-0">
                <div className="box-shadow-black-50 rounded flex-grow-1 d-flex flex-column">
                  <div className="background-black text-white p-3 rounded-top">
                    <h5 className="m-0">Monthly payments</h5>
                  </div>
                  <div className="p-3 my-auto">
                    {tuitionDiscount || (masterclassOverride && !afterMasterclass) ? (
                      <p className="balance-text mb-2">
                        <del>
                          Regular price: 6 payments of {tuitionMonthlyPayment} ({tuitionMonthlyTotal} total)
                        </del>
                      </p>
                    ) : null}
                    <p className="text-green m-0">
                      <b>
                        6 payments of {tuitionMonthly} ({tuitionMonthlyFull} total)
                      </b>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="d-flex flex-column">
                <div className="box-shadow-black-50 rounded flex-grow-1 d-flex flex-column">
                  <div className="background-black text-white p-3 rounded-top">
                    <h5 className="m-0">Pay in full and save</h5>
                  </div>
                  <div className="p-3 my-auto">
                    {tuitionDiscount || (masterclassOverride && !afterMasterclass) ? (
                      <p className="balance-text mb-2">
                        <del>Regular price: 1 payment of {tuitionFullPayment}</del>
                      </p>
                    ) : null}
                    <p className="text-green m-0">
                      <b>1 payment of {tuitionFull}</b>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <CheckoutButton sku={productSku} price={productValue} slug={productSlug} className={classNames(`my-4`)}>
              Apply Now
            </CheckoutButton>

            {!registrationClosed && (
              <>
                {phaseIteration === 'lateApp' || phaseIteration === 'finalChance' ? (
                  <>
                    <h4 className="mt-4 mb-3">By Popular Demand: Deadline Extended!</h4>
                    <p>
                      <b>FINAL Application Deadline: {tuitionDue}</b>
                    </p>
                    <p>
                      <b>Program Start Date: {programStart}</b>
                    </p>
                    <p>This will take you to a page to submit your $100 refundable deposit, and then you can apply.</p>
                    <p className="balance-text">
                      <b>
                        <i>
                          Pay by {phaseIteration === 'finalChance' ? appDeadline : programStartNoYear} to squeeze in at
                          the last minute — with recordings of welcome calls so you won’t have missed a thing!
                        </i>
                      </b>
                    </p>
                  </>
                ) : (
                  <>
                    <h4 className="text-uppercase mt-4 mb-3">Important Dates</h4>
                    <p>
                      <b>Application Deadline: {appDeadline}</b>
                    </p>
                    <p className="balance-text">
                      <b>
                        {phaseIteration === 'insiderPromo' && !masterclassOverride ? (
                          <>First Payment Due</>
                        ) : (
                          <>Tuition Due</>
                        )}
                        : {tuitionDue}
                      </b>
                    </p>
                    {phaseIteration !== 'insiderPromo' && (
                      <p>
                        <b>Meet the Faculty & Welcome Call: {welcomeCallDate}</b>
                      </p>
                    )}
                    <p>
                      <b>Program Start Date: {programStart}</b>
                    </p>
                    <p>
                      This will take you to a page to submit your $100 refundable deposit, and then you can apply.{' '}
                      {phaseIteration === 'insiderPromo' && !masterclassOverride ? (
                        <>We will be in touch as soon as possible (within 1–2 weeks) about your admission status.</>
                      ) : null}
                    </p>
                    <p>
                      <b>
                        <i>Limited spots available. Submit your deposit and secure your place in the next cohort.</i>
                      </b>
                    </p>
                  </>
                )}
                <p className="balance-text">
                  {phaseIteration === 'insiderPromo' && !masterclassOverride ? (
                    <>
                      Your deposit counts towards your total tuition. If your application is accepted, your space will
                      be held at the FRN Insider price until {tuitionDue}.
                    </>
                  ) : phaseIteration === 'lateApp' ? (
                    <>
                      Your deposit counts towards your total tuition.If your application is accepted, your space will be
                      held until program start date, so you can submit payment.
                    </>
                  ) : phaseIteration === 'finalChance' ? (
                    <>
                      Your deposit counts towards your total tuition. If your application is accepted, your space will
                      be held so you can submit payment.
                    </>
                  ) : (
                    <>
                      Your deposit counts towards your total tuition. If your application is accepted, your space will
                      be held for two weeks, so you can submit payment.
                    </>
                  )}
                </p>
                <p className="text-small">
                  The application takes about 10 minutes to complete.
                  {phaseIteration === 'finalChance' && <> But you do have to ask fast. Don’t delay — join now!</>}
                </p>
                {clarityCall && (
                  <p>
                    <a className={classNames(`text-underline`)} href="/clarity-calls/">
                      Or click here to speak with a program advisor.
                    </a>
                  </p>
                )}
              </>
            )}
            <SalesDisclaimer />
          </>
        )}
      </div>
    );
  }

  function ClarityCall({id, bgColor = 'black', ...props}) {
    return (
      <Section id={id} color={bgColor} className="text-center text-white">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading mb-3">
                Have questions about the program that you want answered before submitting a deposit?
              </h3>
              <p>Book a Clarity Call With a Program Advisor Today (No charge!)</p>
              <Button color="cta" className="mb-0" href="/clarity-calls/">
                Book My Clarity Call!
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }

  useBalanceText();
  return (
    <>
      {version === 'masterclass' && (
        <>
          {ctaVisible && (
            <Section id="pbccm-cta-1" color="purple">
              <Container>
                <Row>
                  <Col>
                    <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5 text-center">
                      <ApplyNowCta />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Section>
          )}

          {clarityCall && <ClarityCall id="pbccm-clarity-1" />}
        </>
      )}

      <Section id="pbcc-leader" color={version === 'masterclass' ? 'white' : 'light-gray'} className="text-center">
        <Container>
          <Row>
            <Col>
              <h4 className="section-heading">Are you ready to be a LEADER in the food revolution movement?</h4>
              <h4 className="m-0">Get certified as a Plant-Based Coach.</h4>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <img src="https://cdn.foodrevolution.org/pbcc/pbcc-badge.png" style={{maxHeight: '190px'}} />
                </div>
              </div>
              <h4 className="mt-0 mb-5">Enrollment is open once per year and is limited to only 300 participants.</h4>
              <p>
                <i>Accreditation or CE units available from:</i>
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <img src="https://cdn.foodrevolution.org/pbcc/lmuf-logos.png" style={{maxHeight: '160px'}} />
                </div>
              </div>
              <Button
                color="cta"
                size="lg"
                spy={true}
                offset={-110}
                to="pbcc-curriculum"
                smooth={true}
                href="#"
                tag={Link}
                className="text-uppercase mt-5 mb-0"
              >
                View the Curriculum
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <SectionImage
        id="pbcc-fulfillment"
        color={version === 'masterclass' ? 'light-gray' : 'white'}
        imgPosition="right"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/1482063218-square.jpg"
      >
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading">Why Join Us?</h3>
              <p>
                {
                  {
                    default: (
                      <>
                        <b>Find Fulfillment and Success</b> Whether you’re a seasoned professional or just starting out
                        on a coaching journey, integrating evidence-based nutrition and behavior change into your life
                        and your career opens doors to endless possibilities.
                      </>
                    ),
                    ad: (
                      <>
                        <b>Find Fulfillment and Success</b> Whether you’re a seasoned professional or just starting out
                        on a coaching journey, integrating evidence-based nutrition and behavior change into your life
                        and career opens doors to endless possibilities.
                      </>
                    )
                  }[variant]
                }
              </p>
              <p>
                {
                  {
                    default: (
                      <>
                        <b>For new or aspiring coaches,</b> this is an exciting opportunity to <b>make an impact</b>{' '}
                        with your passion for health and well-being, creating positive change from the start.{' '}
                        <b>If you’re an experienced coach or health professional,</b> embracing the plant-based coaching
                        approach <b>adds a powerful dimension to your existing expertise,</b> enhancing your ability to
                        support clients on their wellness journeys.
                      </>
                    ),
                    ad: (
                      <>
                        <b>For new or aspiring coaches,</b> this is an exciting opportunity to <b>make an impact</b>{' '}
                        using a passion for health and well-being to create positive change.{' '}
                        <b>For experienced coaches or health professionals,</b> embracing the plant-based coaching
                        approach <b>adds a powerful dimension to any existing expertise,</b> enhancing the ability to
                        support clients on their wellness journeys.
                      </>
                    )
                  }[variant]
                }
              </p>
              <p>
                {
                  {
                    default: (
                      <>
                        <b>Regardless of your background or your plans,</b> becoming a plant-based coach empowers you to
                        foster transformation, find personal fulfillment, and make a meaningful difference in the lives
                        of others.
                      </>
                    ),
                    ad: (
                      <>
                        <b>Regardless of your background or your plans,</b> it’s possible to foster transformation, find
                        personal fulfillment, and make a meaningful difference in the lives of others as a plant-based
                        coach.
                      </>
                    )
                  }[variant]
                }
              </p>
              <p>
                Now is your chance to join the premier coaching certification that combines in-depth plant-based
                nutrition, results-based coaching skills, and training to help you generate more income and more impact
                — while making a positive difference in the world.
              </p>
            </Col>
          </Row>
        </Container>
      </SectionImage>

      <Section id="" color={version === 'masterclass' ? 'white' : 'light-gray'}>
        <Container>
          <Row>
            <Col>
              <p className="balance-text text-center text-small mb-5">
                <i>
                  These testimonials reflect the experiences of our Plant-Based Coaching Certification graduates. ​​Each
                  of them is unique. And so are you! Your experiences will vary, of course. And no, we never offer
                  compensation for these statements!
                </i>
              </p>
              <TestimonialBubble id="pbccTerriB" bgColor={version === 'masterclass' ? 'light-gray' : 'white'} />
            </Col>
          </Row>
        </Container>
      </Section>

      {version === 'sales' && (
        <>
          {ctaVisible && (
            <Section id="pbcc-cta-1" color="purple">
              <Container>
                <Row>
                  <Col>
                    <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5 text-center">
                      <ApplyNowCta />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Section>
          )}

          {clarityCall && <ClarityCall id="pbcc-clarity-1" />}
        </>
      )}

      <SectionImage
        id="pbcc-good-work"
        color="light-gray"
        imgPosition="left"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/1412206897-square.jpg"
      >
        <h2 className="section-heading">Do Work That Makes A Difference</h2>
        <p>This is for you if you want to:</p>
        <ListIcon className="my-3">
          <>
            Bring evidence-based and plant-based nutrition and behavior changes into your existing healthcare or
            coaching practice;
          </>
          <>Start a plant-based coaching practice doing work you love;</>
          <>Gain meaningful employment or add to your professional experience;</>
          <>Support healthy behavior change in the lives of the people you love most (starting with yourself!); or</>
          <>Be an effective agent of change within your community</>
        </ListIcon>
        <h5 className="mt-4">It’s time to take your place as a leader in the plant-based food revolution!</h5>
      </SectionImage>

      <Section id="" color="white" className="text-center">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading mb-3">Curious to hear what our students have to say about PBCC?</h3>
              <p className="text-h5">Plant-Based Coach Spotlights: Their stories in their own words.</p>
            </Col>
          </Row>
          <Row className="align-items-stretch mt-5">
            {studentSpotlight.map((item, key) => (
              <Col xs="12" lg="6" className="d-flex flex-column mb-5 px-3" key={key}>
                <div className="d-flex flex-column flex-grow-1 box-shadow-black-50 rounded-top">
                  <div className="my-auto p-3">
                    <p className="mb-2">
                      <b>{item.name}</b>
                    </p>
                    <p className="balance-text mb-0">{item.title}</p>
                  </div>
                  <div className="spotlight-video">
                    <Video url={item.videoUrl} label={`pbcc student spotlight ${item.name}`} />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>

      {version === 'sales' && (
        <>
          <SectionImage
            id="pbcc-needs"
            color="light-gray"
            imgPosition="right"
            //@ts-ignore
            imgUrl={require('static/backgrounds/955820518.jpg')}
          >
            <h2 className="section-heading text-uppercase">The World Needs You</h2>
            <p>
              {
                {
                  default: (
                    <>
                      Despite advances in modern medicine, people are sicker than ever, and it seems only to be getting
                      worse. Rates of heart disease, obesity, type 2 diabetes, autoimmune conditions, osteoporosis,
                      Alzheimer’s, and other chronic lifestyle diseases are spiraling out of control.
                    </>
                  ),
                  ad: (
                    <>
                      Despite advances in modern medicine, people are sicker than ever, and it seems only to worsen.
                      Rates of chronic lifestyle diseases are spiraling out of control.
                    </>
                  )
                }[variant]
              }
            </p>
            <p>
              {
                {
                  default: (
                    <>
                      The US now spends more than $4 trillion per year on medical care, and up to 80% of that spending
                      is on chronic conditions, many of which could be prevented or even reversed with diet and
                      lifestyle changes.
                    </>
                  ),
                  ad: (
                    <>
                      The US now spends more than $4 trillion per year on medical care, and up to 80% of that spending
                      is on chronic conditions that are linked to food and lifestyle.
                    </>
                  )
                }[variant]
              }
              <sup>1</sup>
            </p>
            <p>
              {
                {
                  default: (
                    <>
                      Decades of evidence-based research show that{' '}
                      <b>a whole-food, plant-based diet is the number one way to promote lasting health.</b>
                    </>
                  ),
                  ad: (
                    <>
                      Decades of evidence-based research show us that{' '}
                      <b>a whole foods, plant-based diet is the preferred diet of the world’s longest-living people.</b>
                    </>
                  )
                }[variant]
              }
            </p>
            <p>
              {
                {
                  default: (
                    <>
                      Adopting a plant-based diet is also proven to <b>protect the environment in profound ways.</b> And
                      more people than ever are asking for <b>a cruelty-free approach to eating</b> that doesn’t depend
                      on animal products.
                    </>
                  ),
                  ad: (
                    <>
                      Adopting a plant-based diet is also <b>shown to protect the environment in profound ways.</b> And
                      more people than ever are asking for <b>a cruelty-free approach to eating</b> that doesn’t depend
                      on animal products.
                    </>
                  )
                }[variant]
              }
            </p>
          </SectionImage>

          <SectionImage
            id="pbcc-wait"
            color="white"
            imgPosition="left"
            imgUrl="https://cdn.foodrevolution.org/pbcc/images/1339138376-square.jpg"
          >
            <h2 className="section-heading">This can’t wait.</h2>
            <p>
              {
                {
                  default: (
                    <>
                      Animal agriculture is one of the most environmentally destructive practices on Earth — fueling
                      deforestation, air and water pollution, topsoil erosion, and climate chaos. The single act of
                      raising livestock for food is responsible for more greenhouse gas emissions than the sum total of
                      all transportation emissions.
                    </>
                  ),
                  ad: (
                    <>
                      Animal agriculture is one of the most environmentally destructive practices on Earth — fueling
                      deforestation, air and water pollution, topsoil erosion, and environmental chaos. The single act
                      of raising livestock for food is responsible for more greenhouse gas emissions than the sum total
                      of all transportation emissions.
                    </>
                  )
                }[variant]
              }
              <sup>2</sup>
            </p>
            <p>
              Replacing animal products with plant-based food is critical to the sustainability of ecosystems and the
              wellbeing of future generations.
            </p>
            <h5 className="mt-0">
              {
                {
                  default: (
                    <>The survival of our species and countless others is in our hands, and there’s no time to waste.</>
                  ),
                  ad: <>There’s no time to waste.</>
                }[variant]
              }
            </h5>
          </SectionImage>
        </>
      )}

      <SectionImage
        id="pbcc-change"
        color="light-gray"
        imgPosition="right"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/871231060-square.jpg"
      >
        <h2 className="section-heading">More people than ever are and motivated to make a change.</h2>
        <p>
          More than half of all Americans report that they are trying to eat more plant-based foods.<sup>3</sup>
        </p>
        {variant === 'default' && (
          <p>
            Hundreds of millions of people are going plant-based. And now we’re seeing vegetarian burgers in Burger King
            and Carl’s Jr, and sales of oat, almond, and other non-dairy milk taking a serious bite out of the dairy
            industry.
          </p>
        )}
      </SectionImage>

      <SectionImage
        id="pbcc-incredible-change"
        color="white"
        imgPosition="left"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/1160038719-square.jpg"
      >
        <h3 className="section-heading">
          That’s <i>incredible</i> news. <span className="text-nowrap">Large-scale</span> change is underway.
        </h3>
        <h5 className="mt-0">But…</h5>
        <p>
          Unfortunately, a lot of people are going “plant-based” without guidance or support, and in some cases, causing
          more harm than good.
        </p>
        <ListIcon iconColor="red" icon={faTimes}>
          <>
            They’re putting themselves at risk of missing out on critical nutrients like B12, DHA, EPA, vitamin D, and
            even iodine and zinc.
          </>
          <>
            They’re often replacing animal products with processed vegan junk food — which can sometimes even lead to
            worse health outcomes.
          </>
          <>
            They’re struggling with how to handle their cravings for their old favorites, and over time, social
            pressures, old habits, or lack of energy may drag them back.
          </>
          <>
            {
              {
                default: (
                  <>
                    Or they’re struggling with ongoing health issues but unaware of the long list of extraordinary
                    benefits linked to a whole food, plant-based diet — including slashing the risk of heart disease,
                    cancer, obesity, type 2 diabetes, depression, and more.
                  </>
                ),
                ad: (
                  <>
                    Or they’re struggling with ongoing health issues but unaware of the long list of extraordinary
                    benefits linked to a whole food, plant-based diet — including slashing the risk of chronic disease.
                  </>
                )
              }[variant]
            }
          </>
        </ListIcon>
        <p>
          And most people still don’t know about the benefits of a <b>whole foods</b> plant-based diet.
        </p>
        <h5 className="mt-0">That’s where the right coach comes in. That’s where YOU come in.</h5>
      </SectionImage>

      <Section id="" color="light-gray">
        <Container>
          <Row>
            <Col>
              {['pbccWendyM', 'pbccElizabethB', 'pbccTaiSummers'].map((key, i) => (
                <TestimonialBubble id={key} key={key} bgColor="white" className={classNames(i === 2 ? '' : 'mb-5')} />
              ))}
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-joinus" color="black" className="text-center text-white">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading">
                <i>
                  {
                    {
                      default: <>Help people live longer, happier, and healthier lives</>,
                      ad: <>Help people live long, happy, and healthy lives</>
                    }[variant]
                  }
                </i>
              </h3>
              {ctaVisible && phase != 'after' ? (
                <Button
                  color="cta"
                  size="lg"
                  spy={true}
                  offset={-110}
                  to="pbcc-cta-2"
                  smooth={true}
                  href="#"
                  tag={Link}
                >
                  Join Us
                </Button>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-help-impact" color="white">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading text-center">Do you want to help change the way the world eats?</h3>
              <p className="balance-text text-center mb-5">
                We urgently need leaders, experts, and coaches who can bring this food-based movement to more people,
                and in a deeper, more personal way with:
              </p>
              <ImgCard //@ts-ignore
                img={require('static/backgrounds/854725372-wide.jpg')}
                className="imgBulletCard-300 mb-5"
              >
                <h5 className="section-heading">1) Nutrition.</h5>
                <p className="mb-0">Life-changing, evidence-based, whole foods plant-based nutrition information.</p>
              </ImgCard>
              <ImgCard
                img="https://cdn.foodrevolution.org/pbcc/images/1218076467.jpg"
                className="imgBulletCard-300 mb-5"
              >
                <h5 className="section-heading">2) Coaching.</h5>
                <p className="mb-0">
                  Skills to help people create lasting and meaningful behavior change for themselves, their families,
                  and their communities.
                </p>
              </ImgCard>
              <ImgCard
                img="https://cdn.foodrevolution.org/pbcc/images/1411238326.jpg"
                className="imgBulletCard-300 mb-5"
              >
                <h5 className="section-heading">3) Impact.</h5>
                <p className="mb-0">
                  Advance the food revolution by starting or growing a coaching business, gaining employment and
                  professional skills, or working to influence change in your family and community.
                </p>
              </ImgCard>
              <h5 className="text-center">
                That’s why we’ve designed the world’s most comprehensive plant-based coaching certification program,
                combining all three of these critical pillars to help today’s brightest and most passionate leaders step
                deeper into their roles as agents for change.
              </h5>
            </Col>
          </Row>
        </Container>
      </Section>

      <SectionImage
        id="pbcc-imagine"
        color="light-gray"
        imgPosition="right"
        //@ts-ignore
        imgUrl={require('static/backgrounds/1396510521.jpg')}
      >
        <h4 className="section-heading text-uppercase text-center text-lg-left">Imagine…</h4>
        <ListIcon>
          <>Knowing the latest plant-based nutrition facts</>
          <>
            {
              {
                default: (
                  <>
                    Having confidence in your ability to do more than just educate your clients and community members —
                    but also to help them make lasting changes
                  </>
                ),
                ad: (
                  <>
                    Having confidence to be able to do more than just educate clients and community members — but also
                    to help them make lasting changes
                  </>
                )
              }[variant]
            }
          </>
          <>Enjoying a steady and expanding stream of income earned by helping people get healthy</>
          <>
            {
              {
                default: <>Creating a ripple effect of positive change within your social networks</>,
                ad: <>Creating a ripple effect of positive change</>
              }[variant]
            }
          </>
          <>
            {
              {
                default: (
                  <>
                    Experiencing your own health transformation as you put your nutrition training to use in your own
                    life
                  </>
                ),
                ad: <>Experiencing a personal health transformation by individually applying nutrition training</>
              }[variant]
            }
          </>
          <>
            Understanding digital marketing, with the potential to make a difference and earn money from things like
            blogging, podcasts, social media, speaking on stages, building a membership community, writing books, and
            hosting online summits
          </>
          <>
            {
              {
                default: <>Getting tear-filled testimonials from your clients thanking you for changing their lives</>,
                ad: <>Getting tear-filled testimonials from clients thankful for changing their lives</>
              }[variant]
            }
          </>
          <>
            {
              {
                default: (
                  <>Getting referrals easily — because your clients or patients rave about you to everyone they know</>
                ),
                ad: (
                  <>
                    Getting referrals easily — because clients or patients are sharing their positive experience with
                    everyone they know
                  </>
                )
              }[variant]
            }
          </>
          <>
            {
              {
                default: (
                  <>
                    Feeling proud, knowing that you’re helping to end intergenerational patterns of poor health for the
                    people you serve
                  </>
                ),
                ad: <>Feeling proud, helping to end intergenerational patterns of poor health</>
              }[variant]
            }
          </>
        </ListIcon>
      </SectionImage>

      <Section id="pbcc-intro" color="white" className="text-center">
        <Container>
          <Row>
            <Col>
              <p className="mb-2">
                <i>Introducing</i>
              </p>
              <img
                className="img-course-logo mb-3"
                src="https://cdn.foodrevolution.org/pbcc/pbcc-logo.svg"
                alt="plant-based coaching certification logo"
              />
              <h5 className="text-400 mt-0">
                The Premier Coaching Certification to Combine Plant-Based Nutrition, Coaching, and Impact
              </h5>
              <h3 className="mt-5">Together, We Can Create</h3>
              <ImgCard
                //@ts-ignore
                img={require('static/backgrounds/1127239720-wide.jpg')}
                className="imgBulletCard-300 mb-4 text-h5"
              >
                A movement that stands up to the status quo
              </ImgCard>
              <ImgCard
                //@ts-ignore
                img={require('static/backgrounds/1325809383-wide.jpg')}
                className="imgBulletCard-300 mb-4 text-h5"
              >
                A world where people who are working for good can make a healthy living
              </ImgCard>
              <ImgCard
                img="https://cdn.foodrevolution.org/pbcc/images/1421248586.jpg"
                className="imgBulletCard-300 mb-4 text-h5"
              >
                A flexible work schedule where you get to inspire meaningful change for individuals, families, and
                communities
              </ImgCard>
              {variant === 'default' && (
                <ImgCard
                  //@ts-ignore
                  img={require('static/backgrounds/956366756-wide.jpg')}
                  className="imgBulletCard-300 mb-4 text-h5"
                >
                  A healthier, less polluted planet
                </ImgCard>
              )}
              <ImgCard
                //@ts-ignore
                img={require('static/backgrounds/1174000116-wide.jpg')}
                className="imgBulletCard-300 mb-4 text-h5"
              >
                A new standard of ethics that respects all of life (including animals!)
              </ImgCard>
              {variant === 'default' && (
                <ImgCard
                  //@ts-ignore
                  img={require('static/backgrounds/1360434413-wide.jpg')}
                  className="imgBulletCard-300 mb-4 text-h5"
                >
                  An end to chronic diseases that can be prevented with diet and lifestyle
                </ImgCard>
              )}
              <ImgCard
                //@ts-ignore
                img={require('static/backgrounds/1198049185-wide.jpg')}
                className="imgBulletCard-300 mb-4 text-h5"
              >
                <b>A healthy living, promoting healthy people and a healthy planet</b>
              </ImgCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-testimonials-1" color="light-gray">
        <Container>
          <Row>
            <Col>
              <TestimonialBubble id="pbccBrendaD" bgColor="white" />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-for-you" color="white">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-center">This Is For You If You Are…</h2>
            </Col>
          </Row>
          <Row>
            <VerticalCard
              img="https://cdn.foodrevolution.org/pbcc/images/1143103422-20231005.jpg"
              heading="Passionate about the power of a whole-foods, plant-based lifestyle"
              colLg="4"
            >
              {
                {
                  default: <>and ready to spread the word so you can help people heal their lives</>,
                  ad: <>and ready to spread the word to help people heal their lives</>
                }[variant]
              }
            </VerticalCard>
            <VerticalCard
              img="https://cdn.foodrevolution.org/pbcc/images/1034036368.jpg"
              heading="An aspiring leader"
              colLg="4"
            >
              {
                {
                  default: (
                    <>
                      who wants to take the plant-based revolution to your communities — and to affect change on a local
                      and global level
                    </>
                  ),
                  ad: (
                    <>
                      who wants to take the plant-based revolution to different communities — and to affect change on a
                      local and global level
                    </>
                  )
                }[variant]
              }
            </VerticalCard>
            <VerticalCard
              //@ts-ignore
              img={require('static/backgrounds/1293735269-wide.jpg')}
              heading="Looking to make a difference by doing something that really matters"
              colLg="4"
            >
              {
                {
                  default: (
                    <>
                      with the potential to make a living helping people create lasting and health-promoting habit
                      change
                    </>
                  ),
                  ad: (
                    <>
                      with the potential to make a living helping people create lasting and health-promoting habit
                      change
                    </>
                  )
                }[variant]
              }
            </VerticalCard>
          </Row>
          <Row className="text-center mt-4">
            <Col>
              <h5 className="section-heading">
                And you want to be a positive influence on the future of the planet and all its inhabitants.
              </h5>
              <p className="balance-text">
                Food Revolution Network’s Plant-Based Coaching Certification is open to an extremely limited number of
                participants <b>(just 300!)</b> who want to expand their current skills to include plant-based nutrition
                coaching. Enrollment opens only once a year to ensure a cohesive cohort of students in a small-group
                learning environment.
              </p>
              <p className="balance-text">
                {
                  {
                    default: (
                      <>
                        Upon completion, you’ll be able to put your skills to use in a practically limitless variety of
                        settings, from your own coaching business to wellness centers, spas, holistic healthcare
                        offices, senior centers, schools, restaurants, faith-based communities, at the government level,
                        with your own family and friends, and more.
                      </>
                    ),
                    ad: (
                      <>
                        Upon completion, you’ll be able to put your skills to use in a practically limitless variety of
                        settings, from your own coaching business to wellness centers, spas, holistic healthcare
                        offices, senior centers, schools, restaurants, faith-based communities, with your own family and
                        friends, and more.
                      </>
                    )
                  }[variant]
                }
              </p>
              {variant === 'default' && <h3 className="mt-4 mb-3">The first transformation is your own</h3>}
              <p>
                {
                  {
                    default: (
                      <>
                        Whether you are already plant-based, looking to become plant-based, or simply want to eat more
                        highly nutritious plant-based foods, you’ll gain a world-class education in the nutrition
                        foundation that will benefit your own health and well-being.
                      </>
                    ),
                    ad: (
                      <>
                        Whether you are already plant-based or simply want to eat more highly nutritious plant-based
                        foods, you’ll gain a world-class education in the nutrition foundation that will benefit your
                        own health and well-being.
                      </>
                    )
                  }[variant]
                }
              </p>
              {
                {
                  default: (
                    <>
                      <p>
                        You’ll learn the dietary pattern that’s followed by the world’s longest-living people so you can
                        incorporate the planet’s healthiest food into your own diet — as you reap the rewards of more
                        energy, greater joy and vitality, disease prevention, and longevity.
                      </p>
                      <p>
                        And you’ll gain cutting-edge insights into the science of behavior change. Why do we do what we
                        do? How can we change our habits for lasting results? What you learn will change your life in
                        profound ways. And it will also give you critical skills to enable you to be an effective
                        changemaker in the lives of others.
                      </p>
                    </>
                  ),
                  ad: (
                    <>
                      <p>
                        The program shows you the dietary pattern that’s followed by the world’s longest-living people
                        so you can incorporate the planet’s healthiest food into your own diet — and reap the rewards.
                      </p>
                    </>
                  )
                }[variant]
              }
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-testimonials-2" color="light-gray">
        <Container>
          <Row>
            <Col>
              {['pbccTraceyT', 'pbccBonnieN'].map((key, i) => (
                <TestimonialBubble id={key} key={key} bgColor="white" className={classNames(i === 1 ? '' : 'mb-5')} />
              ))}
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-icons" color="white">
        <Container>
          <Row className="align-items-stretch justify-content-center text-center">
            {iconList.map((item, i) => (
              <Col xs="6" lg="4" className="d-flex flex-column mb-4">
                <div className="text text-800 mb-3">{item.text}</div>
                <div className="icon mt-auto">
                  <img src={item.icon} style={{maxHeight: '150px'}} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>

      <SectionImage
        id="pbcc-questions"
        color="light-gray"
        imgPosition="left"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/1371116757-square.jpg"
      >
        <h4 className="section-heading">100% Online Access</h4>
        <p>
          {
            {
              default: (
                <>
                  Seamlessly weave your studies into your life with a combination of synchronous and asynchronous
                  learning opportunities. All live components are recorded, so you can absorb them any time you like.
                  You’ll have access to the training material even after you graduate so that you can return to it
                  anytime.
                </>
              ),
              ad: (
                <>
                  Seamlessly weave together studying and life with a combination of synchronous and asynchronous
                  learning opportunities. All live components are recorded so they can be absorbed at any time. Get
                  access to the training material even after graduation that can be returned to anytime.
                </>
              )
            }[variant]
          }
        </p>
      </SectionImage>

      <Section id="pbcc-curriculum" color="black" className="text-white">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-center text-uppercase mb-4">Curriculum & Faculty</h2>
              <p className="text-center balance-text">
                Our three-pillar training consists of video/audio lessons, transcripts, reusable handouts, live Q&A
                calls and groups, live group Practice Pods, and written materials for a dynamic and interactive learning
                experience.
              </p>
              <p className="text-center balance-text">
                Pillars 1 (Nutrition) and 2 (Coaching) contain practice comprehension quizzes, as well as a midterm and
                final exam — exams are required if you want to achieve certification. Pillar 3 (Impact) provides
                top-notch information on how to take your learnings and share them with as many people in the world as
                possible, whether that be through a coaching practice, employment, or as a community leader.
              </p>
              <p className="text-center balance-text">
                Participation in Pillar 3 is encouraged to help you make the Impact you want to make in the world, but
                is not required for certification.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <PillarModulesCondensed variant={variant} />

      <Section id="pbcc-testimonials-3" color="light-gray">
        <Container>
          <Row>
            <Col>
              {['pbccJonJew', 'pbccAnnL', 'pbccTeriT'].map((key, i) => (
                <TestimonialBubble bgColor="white" id={key} key={key} className={classNames(i === 3 ? '' : 'mb-5')} />
              ))}
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-bonuses" color="white">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-uppercase text-center mb-5">Bonuses</h2>
              {bonus.map((bonus, i) => {
                if (
                  (!frsSpecialBonus && bonus.id === 'bonusEmpowerment2025') ||
                  (frsSpecialBonus && bonus.id === 'bonusEmpowerment2425') ||
                  (!frsBonus && bonus.id === 'bonusEmpowerment2425') ||
                  (!wlcBonus && bonus.id === 'bonusWLC') ||
                  (!mastermindBonus && bonus.id === 'bonusMastermind')
                ) {
                  return null;
                }
                return (
                  <div key={bonus.id}>
                    {bonus.specialOffer && (
                      <h4 className="section-heading text-center text-green">{bonus.specialOffer}</h4>
                    )}
                    <h3 className="section-heading text-center mb-4">{bonus.title}</h3>
                    {bonus.image && bonus.image.url ? (
                      <div className="text-center">
                        <img
                          className="mb-4"
                          src={bonus.image.url}
                          alt={bonus.image.alt}
                          style={{maxHeight: '500px'}}
                        />
                      </div>
                    ) : null}
                    <ReactMarkdown source={bonus.description} />
                    <h6 className="mt-0">
                      <i>Value: {bonus.value}</i>
                    </h6>
                    {bonus.free && (
                      <h6 className="mt-0 text-400">
                        <i>{bonus.free}</i>
                      </h6>
                    )}
                    {bonus.id === 'bonusNextLevel' && bonus.faculty ? (
                      <>
                        <h5 className="section-heading mt-4">Guest Faculty & Lessons:</h5>
                        <div className="background-white box-shadow-black-50 rounded p-4 p-lg-5">
                          {bonus.faculty.map((item, i) => {
                            return (
                              <div key={'faculty' + i}>
                                <h4 className="section-heading">{item.lesson}</h4>
                                {speakers
                                  .filter((speaker) => speaker.id === item.speaker)
                                  .map((speaker) => {
                                    return (
                                      <Row className="align-items-center">
                                        <Col xs="auto">
                                          <img
                                            className="box-shadow-image rounded-circle"
                                            src={speaker.image}
                                            style={{maxHeight: '100px'}}
                                          />
                                        </Col>
                                        <Col xs="8" lg="10">
                                          <h6 className="mt-0 mb-2">{speaker.name}</h6>
                                          {speaker.bio ? (
                                            <PillarModal
                                              name={speaker.name}
                                              image={speaker.image}
                                              bio={speaker.bio}
                                              className="text-"
                                            />
                                          ) : null}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                {i == bonus.faculty.length - 1 ? null : <hr className="my-4" />}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    {bonus.id === 'bonusPlantrician' && (
                      <TestimonialBubble id="pbccChefAgatheMBonus" className="mt-4" />
                    )}
                    <hr className="my-5" />
                  </div>
                );
              })}
              {bonusesValue && <h5 className="text-center">Total Value of Bonuses: Over {bonusesValue}</h5>}
            </Col>
          </Row>
        </Container>
      </Section>

      <SectionImage
        id="pbcc-revolutionize"
        color="light-gray"
        imgPosition="left"
        imgUrl="https://cdn.foodrevolution.org/pbcc/images/916392736-square.jpg"
        title={
          <div className="text-center">
            <h3 className="section-heading">
              {
                {
                  default: <>Use Your Plant-Based Coaching Certification to Revolutionize the World</>,
                  ad: <>Use the Plant-Based Coaching Certification to Revolutionize the World</>
                }[variant]
              }
            </h3>
            <p>Gain the skills you need to step onto your path with clarity and confidence. You could:</p>
          </div>
        }
        titleClass="mb-0"
      >
        <Container>
          <Row>
            <Col>
              <ListIcon>
                <>Fuel the movement to help more people achieve their health goals</>
                <>Start a podcast, become a world-class speaker, or write a book to reach thousands of people at once</>
                <>Support yourself, your family, and your future, and have a better work-life balance</>
                <>
                  Work with clients from anywhere in the world (with the freedom to travel for months each year if you
                  want!)
                </>
                <>Become an outspoken advocate for change within the food industry</>
                <>Advocate for wellness in disadvantaged populations</>
                <>
                  {
                    {
                      default: <>Create a ripple effect of healing within entire families and communities</>,
                      ad: <>Create a positive ripple effect within entire families and communities</>
                    }[variant]
                  }
                </>
                <>
                  {
                    {
                      default: (
                        <>
                          Be a vital part of the solution to climate change, deforestation, species extinction, and
                          animal cruelty
                        </>
                      ),
                      ad: (
                        <>
                          Be a vital part of the fight against environmental chaos, deforestation, species extinction,
                          and animal cruelty
                        </>
                      )
                    }[variant]
                  }
                </>
              </ListIcon>
            </Col>
          </Row>
        </Container>
      </SectionImage>

      <Section id="pbcc-includes" color="white">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading text-center">
                Food Revolution Network Plant-Based Coaching Certification Includes
              </h3>
              <div className="d-flex justify-content-center">
                <ListIcon>
                  <>
                    Unlimited access to more than 75 lessons in Nutrition, Coaching, and Impact, taught by top experts
                  </>
                  <>More than 200 handouts, templates and guides</>
                  <>Live Q&A and training calls</>
                  <>Live Practice Pods and Impact Sessions</>
                  <>Community Forum moderated by our team of experts</>
                  <>Certificate upon Successful Completion</>
                  <>Qualifying graduates can receive 24 CE/CPD credits through NBHWC and CPD Standards UK</>
                  {wlcBonus && (
                    <>
                      <b className="text-green">Limited-Time Bonus:</b> Unlimited access to WHOLE Life Club
                    </>
                  )}
                  {frsBonus && (
                    <>
                      {frsSpecialBonus ? (
                        <>
                          <b className="text-green">Limited-Time Bonus:</b> 2020–2025
                        </>
                      ) : (
                        <>
                          <b className="text-green">Bonus:</b> 2024 and 2025
                        </>
                      )}{' '}
                      Food Revolution Summit Empowerment Packages
                    </>
                  )}
                  {mastermindBonus && (
                    <>
                      <b className="text-green">Limited-Time Bonus:</b> Access to Ocean Robbins’ Food Revolution
                      Mastermind Mastermind
                    </>
                  )}
                  <>
                    <b className="text-green">Bonus:</b> Next Level Collection
                  </>
                  <>
                    <b className="text-green">Bonus:</b> Qualifying graduates receive one free year of professional
                    listing in Plantrician Project’s directory of plant-based healthcare providers
                  </>
                  <>
                    <b className="text-green">Bonus:</b> Discounted pricing for Coaches Console
                  </>
                  <>
                    <b className="text-green">Bonus:</b> Discounted pricing on Root-Cause Health Coaching
                  </>
                </ListIcon>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      {ctaVisible && (
        <Section id="pbcc-cta-2" color="purple">
          <Container>
            <Row>
              <Col>
                <div className="text-center text-white">
                  <h2 className="section-heading text-uppercase mb-3">
                    {registrationClosed ? <>Enrollment</> : <>Your Investment</>}
                  </h2>
                  <p className="balance-text mb-5">
                    We’ve created a competitively priced, robust curriculum to help you establish and grow your niche,
                    increase your impact, and be a potent leader in the food revolution movement.
                  </p>
                </div>
                <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5 text-center">
                  <ApplyNowCta />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      {clarityCall && <ClarityCall id="pbcc-clarity-2" bgColor="black" />}

      <Section id="pbcc-testimonials-5" color="light-gray">
        <Container>
          <Row>
            <Col>
              {['pbccTeklaG', 'pbccMicheleW'].map((key, i) => (
                <TestimonialBubble bgColor="white" id={key} key={key} className={classNames(i === 2 ? '' : 'mb-5')} />
              ))}
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="pbcc-faq" color="white">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading">Frequently Asked Questions</h2>
              <FaqList clarityCall={clarityCall} phaseIteration={phaseIteration} />
            </Col>
          </Row>
        </Container>
      </Section>

      {ctaVisible && (
        <Section id="pbcc-cta-3" color="purple">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center text-white mb-3">
                  A better world is possible — let’s make it happen together!
                </h2>
                <h3 className="mt-0 text-center text-white mb-5">
                  Join us as we create a food revolution that ignites positive change on a global scale!
                </h3>
                <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5 text-center">
                  <ApplyNowCta />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      {clarityCall && <ClarityCall id="pbcc-clarity-3" bgColor="black" />}

      <Section id="pbcc-references" color="light-gray" angle="none" className="section-angle-last">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading">References</h3>
              <ListReferences>
                {references.map((reference, i) => (
                  <ListReferenceItem number={i + 1} text={reference.link} />
                ))}
              </ListReferences>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}
